@import 'styles/colors.scss';

.box {
  position: relative;

  width: 100%;
  min-width: 0;
  min-height: 100px;
  padding: 20px;

  border-radius: 10px;
  border-style: solid;
  border-color: $graySecondaryLightest;

  background-color: $white;
}
