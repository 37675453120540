@import 'styles/size.scss';

.notifications {
  position: fixed;
  z-index: 4;
  top: 80px;
  right: 20px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  width: $notificationsWidth;
}
