@import 'styles/size.scss';
@import 'styles/positions.scss';

.container {
  position: relative;

  width: 85%;
  margin: $defaultContainerSpacing auto 0;
  padding-bottom: $defaultContainerSpacing;
}

.centered {
  @extend %flex-centered;
}
