@import 'styles/colors.scss';

.inputWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 20px 20px 30px;
}

.sgInputRadio {
  width: 25px;
  height: 25px;

  cursor: pointer;

  border-radius: 50%;
  background: $graySecondaryLightest;
  &Disabled {
    cursor: not-allowed;
  }

  &Checked:not(&Disabled) {
    border: 6px solid $bluePrimary;
    background: $white;
  }

  &:hover:not(&Checked):not(&Disabled) {
    background: $graySecondaryLight;
  }
}
