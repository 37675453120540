@import 'styles/colors.scss';
@import 'styles/spacing.scss';

:export {
  fontColor: $grayPrimary;
}

.select {
  overflow: unset;

  min-width: 200px;
  height: unset;

  @extend %space-bottom;
}

.required {
  margin-left: 2px;

  color: $peachPrimaryDark;
}
