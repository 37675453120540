@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.buttonsWrapper {
  display: flex;

  @include mobileMediaQuery {
    flex-wrap: wrap;
  }
}

.tabButton {
  font-size: 1.5em;
  font-weight: bold;

  position: relative;

  flex-basis: 100%;
  flex-grow: 1;

  width: 100%;
  height: 55px;
  margin-bottom: 30px;
  padding: 0 24px;

  text-transform: uppercase;

  color: $graySecondaryLight;
  border: none;
  border-radius: 0;
  outline: none;
  background: $graySecondaryLightest;

  @include mobileMediaQuery {
    font-size: 15px;
  }
  &:focus {
    background-color: $white !important;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  &--active {
    color: $black;
    background-color: $white;
    &::after {
      position: absolute;
      bottom: -4px;
      left: 0;

      width: 30%;
      height: 4px;

      content: '';
    }
  }
  &--mint.tabButton--active {
    color: $mintPrimaryDark;
    &::after {
      background-color: $mintPrimaryDark;
    }
  }
  &--mustard.tabButton--active {
    color: $mustardPrimaryDark;
    &::after {
      background-color: $mustardPrimaryDark;
    }
  }
  &--peach.tabButton--active {
    color: $peachPrimaryDark;
    &::after {
      background-color: $peachPrimaryDark;
    }
  }
}
