@import 'styles/mixins.scss';

.tooltip {
  position: relative;

  .tooltipText {
    font-size: 16px;
    font-weight: normal;

    position: absolute;
    z-index: 3;
    bottom: 100%;
    left: 50%;

    visibility: hidden;

    padding: 5px 20px;

    transform: translateX(-50%);
    white-space: nowrap;
    text-transform: none;

    color: white;
    border-radius: 5px;
    background-color: #555;
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;

      margin-left: -5px;

      content: '';

      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    @include mobileMediaQuery {
      display: none;
    }
  }
  &:hover .tooltipText {
    visibility: visible;
  }
}
