@import 'styles/colors.scss';
@import 'styles/spacing.scss';

.dropdown {
  width: auto;

  @extend %space-bottom;
}

.required {
  margin-left: 2px;

  color: $peachPrimaryDark;
}

.item {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 2rem;
  white-space: nowrap;
}