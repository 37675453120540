@import 'styles/colors.scss';

%font-light {
  font-weight: 100;
}

%disabled {
  text-decoration: line-through;

  color: $graySecondary;
}
