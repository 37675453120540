@import 'styles/colors.scss';
@import 'styles/spacing.scss';

:export {
  fontColor: $graySecondary;
  borderColor: $graySecondaryLight;
}

.chips {
  margin-bottom: 6px;
}

.spaceBottom {
  @extend %space-bottom-double;
}

.disclaimer{
  white-space: normal;
  top: 10px;
  width: 300px;
  line-height: 15px;
}