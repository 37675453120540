@import 'styles/spacing.scss';
@import 'styles/mixins.scss';

.logoContainer {
  display: flex;
  align-items: center;

  width: 80px;
  height: 100%;

  cursor: pointer;

  @extend %space-right;
  @include mobileMediaQuery {
    width: 30px;
  }

  .logoMobile,
  .logoDesktop {
    max-width: 100%;
  }

  .logoMobile {
    display: none;

    @include mobileMediaQuery {
      display: block;
    }
  }

  .logoDesktop {
    @include mobileMediaQuery {
      display: none;
    }
  }
}
