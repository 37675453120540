/* stylelint-disable-line */
@mixin inputContainer($width: 300px) {
  display: flex;
  flex-direction: column;

  width: $width;

  @media only screen and (max-width: 700px) {
    width: unset;
    max-width: $width;
  }
}

@mixin mobileMediaQuery {
  @media (max-width: 800px) {
    @content;
  }
}
