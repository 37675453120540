$animationTime: 500ms;

@keyframes blink {
  50% {
    color: transparent;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}

@keyframes bounce {
  0%,
  50%,
  100% {
    transform: translateY(2.5px);
  }
  25%,
  75% {
    transform: translateY(-2.5px);
  }
}
