@import 'styles/colors.scss';
@import 'styles/spacing.scss';

.textInput {
  @extend %space-bottom;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.inputFileContainer {
  width: 50%;
  height: 40px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 5px #434e66;
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  text-align: center;
  padding-top: 10px;
}

.inputFileContainer:hover {
  box-shadow: 1px 1px 10px #434e66;
}

.inputFileContainer .inputFile {
  filter: alpha(opacity=1);
  -moz-opacity: 0.01;
  width: 100%;
  opacity: 0.01;
  position: absolute;
  left: 0;
  top: 0;
  background: #333;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  display: inline-block;
  border-radius: 10px;
  padding-left: 100%;
  padding-top:15%;
  cursor: pointer;
}


