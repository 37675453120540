@import 'styles/animations.scss';
@import 'styles/colors.scss';
@import 'styles/fonts.scss';
@import 'styles/mixins.scss';
@import 'styles/positions.scss';
@import 'styles/size.scss';
@import 'styles/spacing.scss';

.question{
    font-weight: bolder;
    margin-bottom: 10px;
}

.message{
    margin-bottom: 15px;
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
}

.button {
    margin: 5px;
}