@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.dropdown {
  position: relative;
}

.dropdownList {
  position: absolute;
  z-index: 10;
  right: 0;

  border-radius: 15px;
  white-space: nowrap;

  cursor: pointer;

  background: $white;
  -webkit-box-shadow: 0 3px 16px 0 rgba(2, 10, 27, 0.15);
  box-shadow: 0 3px 16px 0 rgba(2, 10, 27, 0.15);

  @include mobileMediaQuery {
    > * {
      line-height: 1.3;

      height: auto;
      min-height: 2rem;
      padding: 12px;
      padding-top: 0;

      white-space: normal;

      &:first-child {
        padding-top: 12px;
      }
    }
  }
}
