@import 'styles/size.scss';
@import 'styles/animations.scss';

:export {
  animationTime: $animationTime;
}

.toast {
  position: relative;

  margin-bottom: 10px;
  padding: 10px;

  cursor: pointer;
  animation: showToast $animationTime;

  border-radius: 5px;

  &.hidden {
    animation: hideToast $animationTime;
  }
}

.sgFlash {
  width: 100%;
}

@keyframes showToast {
  0% {
    transform: translateX($notificationsWidth);

    opacity: 0;
  }
  100% {
    transform: translateX(0);

    opacity: 1;
  }
}

@keyframes hideToast {
  from {
    transform: translateX(0);

    opacity: 1;
  }
  to {
    transform: translateX($notificationsWidth);

    opacity: 0;
  }
}
