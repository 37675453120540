@import 'styles/colors.scss';

html {
  // fix for jumping page when scroll bar appears and disappears
  overflow-y: scroll;
}

.app {
  min-height: 100vh;
  background-color: $white;
}
