@import 'styles/colors.scss';
@import 'styles/spacing.scss';

.textInput {
  @extend %space-bottom;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
