//Core colors

$black: #000;
$white: #fff;
$mintPrimaryDark: #40bd7e;
$mintPrimary: #60d399;
$mintSecondary: #9ce8c2;
$mintSecondaryLight: #e3f7ed;
$mintSecondaryUltraLight: #f0faf5;
$bluePrimaryDark: #1d9ff5;
$bluePrimary: #4fb3f6;
$blueSecondary: #b9e2fe;
$blueSecondaryLight: #d9f0ff;
$blueSecondaryUltraLight: #edf8ff;

//Additional colors

$lavenderPrimaryDark: #435fee;
$lavenderPrimary: #6d83f3;
$lavenderSecondary: #bdc7fb;
$lavenderSecondaryLight: #ebeeff;
$lavenderSecondaryUltraLight: #f2f4ff;

$peachPrimaryDark: #ff604b;
$peachPrimary: #ff7968;
$peachSecondary: #ffc7bf;
$peachSecondaryLight: #ffe8e5;
$peachSecondaryUltraLight: #fff1f0;

$mustardPrimaryDark: #eda600;
$mustardPrimary: #fbbe2e;
$mustardSecondary: #fedd8e;
$mustardSecondaryLight: #fff3d6;
$mustardSecondaryUltraLight: #fffaf0;



//Neutrals

$grayPrimary: #323c45;
$graySecondary: #687b8c;
$graySecondaryLight: #c3d1dd;
$graySecondaryLightest: #ebf2f7;
$graySecondaryUltraLight: #f5f8fa;
