@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.tab {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: space-between;

  height: 55px;
  margin-right: auto;
  margin-bottom: 4px;

  border-radius: 0;

  @include mobileMediaQuery {
    flex-basis: 100%;
  }
}

.tabHeader {
  font-size: 1.5em;

  display: flex;
  justify-content: space-between;

  @include mobileMediaQuery {
    font-size: 1.1em;
  }

  *:first-child {
    margin-right: 10px;
  }
}

.tabActive {
  background: $white;

  &::after {
    position: absolute;
    bottom: -4px;
    left: 0;

    width: 30%;
    height: 4px;

    content: '';
  }
}

.tabActiveMintPrimaryDark {
  color: $mintPrimaryDark;

  @extend .tabActive;

  &::after {
    background: $mintPrimaryDark;
  }
}

.tabActivePeachPrimaryDark {
  color: $peachPrimaryDark;

  @extend .tabActive;

  &::after {
    background: $peachPrimaryDark;
  }
}

.tabActiveMustardPrimaryDark {
  color: $mustardPrimaryDark;

  @extend .tabActive;

  &::after {
    background: $mustardPrimaryDark;
  }
}

.tabDisabled {
  color: $graySecondaryLight;
  background: $graySecondaryLightest;
}

.buttonText {
  width: 100%;
}
