@import 'styles/colors.scss';
@import 'styles/positions.scss';
@import 'styles/size.scss';
@import 'styles/mixins.scss';

:export {
  topBarHeight: $topBarHeight;
}

.appName {
  margin: 0;

  cursor: default;
  text-transform: uppercase;

  color: $black;

  @include mobileMediaQuery {
    font-size: 12px;
  }
}

.header {
  margin-bottom: 0;
}

.headerContent {
  max-width: unset;

  z-index: 2;
  position: relative;

  @include mobileMediaQuery {
    flex-wrap: wrap;

    height: auto;
    min-height: 3.75rem;
  }
}

.email {
  font-weight: bold;

  margin-right: 10px;

  cursor: default;

  color: $black;

  @include mobileMediaQuery {
    display: none;
  }
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  cursor: pointer;

  background: transparent;
}

.dropdownClosed {
  display: flex;

  width: 8px;
  height: 8px;

  transition: transform 0.2s;

  border-top: 6px solid $graySecondary;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdownOpened {
  transform: rotate(180deg);
  @extend .dropdownClosed;
}

.sgDropdown {
  border: transparent;
  min-height: auto;
  height: 0px;
  margin-bottom: 0px;
}

.sgDropdownItems {
  bottom: auto;
  left: unset;
  overflow: hidden;
  padding: 5px;
  right: 12px;
  top: 26px;
}

%topAlert {
  position: sticky;
  z-index: 2;
  top: 0;

  width: 100%;
  height: 25px;

  color: $white;

  @extend %flex-centered;
}

.disconnected {
  background-color: $peachPrimary;

  @extend %topAlert;
}

.connected {
  background-color: $mintPrimary;

  @extend %topAlert;
}

.alertWrapper {
  overflow: visible;

  height: 0;
}
