@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.headerCell {
  overflow: hidden;

  padding-right: 10px;

  white-space: nowrap;
  text-overflow: ellipsis;

  border-bottom: 1px solid $graySecondaryLightest;
}

.cell {
  @extend .headerCell;
  @extend %font-light;
}

.row {
  &:hover {
    background-color: $blueSecondaryLight;
  }
}

.table {
  width: 100%;

  table-layout: fixed;
  border-spacing: 0;

  text-align: left;

  background: $white;
}

.tableWrapper {
  max-width: 100vw;
}

.scrollContainer {
  overflow-x: auto;
}
