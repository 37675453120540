%space-bottom {
  margin-bottom: 20px;
}

%space-bottom-double {
  margin-bottom: 40px;
}

%space-bottom-triple {
  margin-bottom: 60px;
}

%space-right {
  margin-right: 20px;
}

%space-top {
  margin-top: 20px;
}
